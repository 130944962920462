<template>
  <div id="terms" class="sub_wrap">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Terms",
}
</script>
